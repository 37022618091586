<template>
  <div class="row align-items-end">
    <div class="announcement-container col">
      <h1 class="h1-margin">small pockets</h1>
      <div class="row announcement-container">
        <span class="col-auto arrow">&#8627;</span>
        <a
          href="http://www.masqueradeatlanta.com/events/your-neighbors/"
          class="col-auto announcement"
          target="_blank"
        >
          9.17
          <span style="padding: 0 0.5rem">&#119044;</span>
          Vinyl
          <span style="padding: 0 0.5rem">&#119044;</span>
          Atlanta, GA
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    src: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.h1-margin {
  margin: 0.67em 0 0.15em;
}

.announcement-container {
  margin-left: 2em;
}

.announcement {
  margin: 0;
  color: white;
  background: black;
  text-decoration: none;
  transition-duration: 0.5s;
  transition-property: all;
  border-bottom: 3px white ridge;
  outline-color: transparent;
  // border-bottom: 1px dashed #ffd83d;
  // box-shadow: inset 0px -3px 0px 0px #ffd83d;

  &:hover,
  &:focus {
    color: black;
    background: white;
    outline-color: transparent;
    // border-right: 1px ridge black;
    // border-bottom: 3px black ridge;
    // border-bottom: 1px dashed black;
    // box-shadow: inset 0px -3px 0px 0px black;
  }
}

.arrow {
  padding-right: 5px;
}
</style>
