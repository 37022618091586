import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/presave",
    beforeEnter: () => {
      // eslint-disable-next-line
      window.location.href = "https://distrokid.com/hyperfollow/smallpockets/small-pockets-ep";
    },
    // https://stackoverflow.com/questions/53336917/vuejs-routing-navigation-issues/53337147
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
