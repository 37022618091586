<template>
  <div id="social" class="row justify-content-center">
    <a
      href="https://instagram.com/smallpocketsmusic"
      target="_blank"
      aria-label="Follow small pockets on Instagram"
    >
      <i class="fab fa-instagram" aria-hidden="true"></i>
    </a>

    <a
      href="https://www.youtube.com/channel/UC3nKLX-PNpNVLGvN6WaTIww"
      target="_blank"
      aria-label="Follow small pockets on Youtube"
    >
      <i class="fab fa-youtube" aria-hidden="true"></i>
    </a>

    <a
      href="https://open.spotify.com/artist/5ouHi7p9jmnfVq9IlGf9uu?si=7pCSXbXbQfuu2KIhPWFpIQ"
      target="_blank"
      aria-label="Listen to small pockets on Spotify"
    >
      <i class="fab fa-spotify"></i>
    </a>

    <a
      href="https://music.apple.com/us/artist/small-pockets/1586566276"
      target="_blank"
      aria-label="Listen to small pockets on Apple Music"
    >
      <i class="fab fa-apple"></i>
    </a>

    <!-- <a
      href="https://www.tiktok.com/@smallpocketsmusic"
      aria-label="Email small pockets"
    >
      <i class="fab fa-tiktok" aria-hidden="true"></i>
    </a> -->

    <a
      href="http://twitter.com/smpocketsmusic"
      target="_blank"
      aria-label="Follow small pockets on Twitter"
    >
      <i class="fab fa-twitter" aria-hidden="true"></i>
    </a>

    <a
      href="https://www.facebook.com/smallpocketsmusic/"
      target="_blank"
      aria-label="Follow small pockets on Facebook"
    >
      <i class="fab fa-facebook-f" aria-hidden="true"></i>
    </a>

    <a
      href="mailto:smallpockets.music@gmail.com"
      aria-label="Email small pockets"
    >
      <i class="fa fa-envelope" aria-hidden="true"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialIconsBar",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#social {
  margin-bottom: 1rem;

  a {
    color: black;
    font-size: 1.7rem;
    transition: all 100ms;
    text-decoration: none;

    &:hover,
    &:focus {
      outline: none;
      text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc,
        1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc,
        3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc,
        5px 6px 1px #eee, 7px 6px 1px #ccc;
    }

    &:not(:last-child) {
      margin-right: 1.5rem;

      @media (min-width: 768px) {
        margin-right: 2rem;
      }
    }
  }
}
</style>
