import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(faUserSecret, faFontAwesome);

// Vue.component("font-awesome-icon", FontAwesomeIcon);

//
// createApp(App).use(store).use(router).mount("#app");

const smallPocketsApp = createApp(App);
// smallPocketsApp.component("font-awesome-icon", FontAwesomeIcon);
smallPocketsApp.use(store).use(router);

smallPocketsApp.mount("#app");
