<template>
  <video width="990" height="540" autoplay loop muted playsinline defaultMuted>
    <source src="@/assets/gameboy-white.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <!-- <iframe id="video" width="720" height="405" src="https://www.youtube.com/embed/1L-8wCwciNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
</template>

<script>
export default {
  name: "Video",
  props: {
    src: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// @media (max-width: 900px) {
//   #video {
//     width: 500px;
//     height: 281px;
//   }
// }
//
// @media (max-width: 500px) {
//   #video {
//     width: 320px;
//     height: 180px;
//   }
// }
</style>
