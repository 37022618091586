<template>
  <div id="home" class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <Header />
      </div>

      <Video />

      <div class="col-12">
        <SocialIconsBar />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SocialIconsBar from "@/components/SocialIconsBar.vue";
import Video from "@/components/Video.vue";
import Header from "@/components/Header.vue";

export default {
  name: "Home",
  components: {
    SocialIconsBar,
    Video,
    Header,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
